import React from 'react';
import { PageProps } from 'gatsby';
import MainLayout from '../components/main-layout';

const NotFound: React.FC<PageProps> = () => (
  <MainLayout>
    <div className="text-center text-white py-16 flex flex-col justify-center items-center">
      <h1 style={{ fontSize: '100px' }} className="font-bold">
        Ups..
      </h1>
      <p>Stránka, na ktorú sa pokúšate dostať neexistuje.</p>
    </div>
  </MainLayout>
);

export default NotFound;
